import useLocalI18n from 'Hooks/LocalI18n'
import React, { useEffect, useState, useRef } from 'react'
import Select from 'react-select'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { generateId } from 'Utilities/ids'

import './styles.scss'

const Selector = ({
  className,
  inputId,
  isClearable,
  isMulti,
  isSmall = true,
  onChange,
  options,
  selectId,
  selectedIndex,
  isDisabled = false,
  required = false
}) => {
  const { I18n } = useLocalI18n('elements/Selector/Lang')

  const _mountedRef = useRef(true)
  const [_id, _setId] = useState()
  const [_selected, _setSelected] = useState()
  const [_options, _setOptions] = useState([])

  const _handleChange = (option) => {
    if (_mountedRef.current) _setSelected(option)

    const _input = document.getElementById(selectId)

    if (_input) {
      _input.value = option.value
    }

    if (onChange) {
      onChange(option)
    }
  }

  const _updateLabel = () => {
    const _tempId = `selector-${generateId()}`
    const _curLabel = document.querySelector(`label[for="${selectId}"]`)

    if (_curLabel) {
      _curLabel.setAttribute('for', _tempId)
    }

    if (_mountedRef.current) _setId(_tempId)
  }

  useEffect(() => {
    if (_mountedRef.current)
      _setOptions(
        options.map((item) => ({
          value: item.value,
          label: item.innerText || item.label
        }))
      )
  }, [options])

  useEffect(() => {
    if (!isMulti) {
      if (_mountedRef.current) _setSelected(_options[selectedIndex])
    }
  }, [_options])

  useEffect(() => {
    _updateLabel()
  }, [selectId])

  useEffect(() => {
    return () => {
      _mountedRef.current = false
    }
  }, [])

  return (
    <Select
      className={classnames(
        'Selector',
        {
          'Selector--small': isSmall,
          'Selector--multi': isMulti,
          'Selector--disabled': isDisabled
        },
        className
      )}
      classNamePrefix="selector"
      defaultValue={_selected}
      inputId={inputId || _id}
      isClearable={isClearable}
      isMulti={isMulti}
      onChange={_handleChange}
      options={_options}
      placeholder={I18n.t('selector.placeholder')}
      isDisabled={isDisabled}
      {...(!isMulti && { value: _selected })}
      required={required}
    />
  )
}

Selector.defaultProps = {
  isClearable: false,
  isMulti: false,
  isSmall: false,
  selectedIndex: 0
}

Selector.propTypes = {
  className: PropTypes.string,
  inputId: PropTypes.string,
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool,
  isSmall: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  selectId: PropTypes.string,
  selectedIndex: PropTypes.number,
  isDisabled: PropTypes.bool
}

export default Selector

import I18n from 'i18n-js'
import deepmerge from 'deepmerge'

I18n.defaultLocale = 'en'
I18n.locale = 'en'

if (typeof window !== 'undefined') {
  I18n.locale = window.document?.documentElement?.lang || 'en'
}

I18n.translations = I18n.translations || {}

const _currentLocale = I18n.currentLocale()
const _defaultLocale = I18n.defaultLocale

const combineMerge = (target, source, options) => {
  const destination = target.slice()

  source.forEach((item, index) => {
    if (typeof destination[index] === 'undefined') {
      destination[index] = options.cloneUnlessOtherwiseSpecified(item, options)
    } else if (options.isMergeableObject(item)) {
      destination[index] = deepmerge(target[index], item, options)
    } else if (target.indexOf(item) === -1) {
      destination.push(item)
    }
  })
  return destination
}

const useLocalI18n = (data?: string | string[]) => {
  const addTranslations = (translations) => {
    I18n.translations = deepmerge(I18n.translations, translations, {
      arrayMerge: combineMerge
    })
  }

  const _getTranslations = (path: string) => {
    try {
      addTranslations(
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        require(`@giki_zero/javascript/${path}/${_currentLocale}.yml`)
      )
    } catch (e) {
      /* */
    }

    if (_currentLocale !== _defaultLocale) {
      try {
        addTranslations(
          // eslint-disable-next-line @typescript-eslint/no-var-requires
          require(`@giki_zero/javascript/${path}/${_defaultLocale}.yml`)
        )
      } catch (e) {
        /* */
      }
    }
  }

  _getTranslations('hooks/LocalI18n/Lang')

  if (data) {
    if (typeof data === 'string') {
      _getTranslations(data)
    } else if (Array.isArray(data)) {
      data.forEach((item) => {
        _getTranslations(item)
      })
    } else {
      _getTranslations(data)
    }
  }

  return { I18n }
}

export default useLocalI18n
